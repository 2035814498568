import { t } from "@/i18n-js/instance";
import { TiptapCommentBox } from "@circle-react/components/CommentsV3/CommentForm/TiptapCommentBox";
import {
  useCurrentCommunityMember,
  useCurrentPostContext,
} from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useScrollIntoView } from "@circle-react/hooks/utils/useScrollIntoView";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Form } from "@circle-react-uikit/Form";
import { useCommentForm } from "./useCommentForm";

export interface CommentFormProps {
  spaceId: any;
  postId: any;
  parentCommentId?: any;
  communityMemberForReply?: any;
  autoFocus?: boolean;
  onCreate: (comment: any) => void;
  bodyInputRef?: any;
  className?: string;
}

export const CommentForm = ({
  spaceId,
  postId,
  parentCommentId,
  communityMemberForReply,
  autoFocus = false,
  onCreate,
  bodyInputRef,
  className,
}: CommentFormProps) => {
  const divRef = useScrollIntoView<HTMLDivElement>(!!parentCommentId);
  const currentCommunityMember = useCurrentCommunityMember();
  const { usedIn, isUsedInMinimalTipTapViewModal } = useCurrentPostContext();
  const shouldShowUserImage = usedIn !== "full-post-modal";

  const editorPlaceholder = communityMemberForReply
    ? t("comments.form.fields.body_reply_placeholder", {
        name: communityMemberForReply.name,
      })
    : t("comments.form.fields.body_placeholder");

  const { createComment } = useCommentForm({
    postId,
    parentCommentId,
    onCreate,
  });

  return (
    <div
      className={classNames(
        "post__reply flex items-start gap-3 py-2.5 md:py-5",
        {
          "bg-primary absolute bottom-0 left-0 z-10 w-full !px-5 !py-4 md:!px-8":
            isUsedInMinimalTipTapViewModal && !parentCommentId,
          "md:px-6": usedIn !== "minimal-post-modal",
          "reply-to-parent -ml-8 py-2.5 md:ml-0":
            parentCommentId && usedIn !== "full-post-modal",
          "reply-to-parent -ml-8 md:ml-[-3.125rem]":
            parentCommentId && usedIn === "full-post-modal",
          "reply-to-post px-5": !parentCommentId,
        },
      )}
      ref={divRef}
    >
      {shouldShowUserImage && (
        <UserImage
          src={currentCommunityMember.avatar_url}
          name={currentCommunityMember.name}
          size="10"
        />
      )}
      <Form
        className="relative w-full"
        onSubmit={(formData: any) => createComment(formData)}
      >
        <TiptapCommentBox
          autoFocus={autoFocus}
          className={className}
          communityMemberForReply={communityMemberForReply}
          editorRef={bodyInputRef}
          isEdit={false}
          parentCommentId={parentCommentId}
          placeholder={editorPlaceholder}
          postId={postId}
          spaceId={spaceId}
          usedIn={usedIn}
        />
      </Form>
    </div>
  );
};
