import {
  reactQueryPatch,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const adminMarkAsRead = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.communityInbox.adminMarkAsRead({ id }));

export const memberMarkAsRead = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.communityInbox.memberMarkAsRead({ id }));

export const previewChat = ({
  community_bot_profile_id,
}: {
  community_bot_profile_id: number;
}) =>
  reactQueryPost(internalApi.communityInbox.preview(), {
    community_bot_profile_id,
  });

export const communityInboxApi = {
  adminMarkAsRead,
  memberMarkAsRead,
  previewChat,
};
