import { useEffect, useState } from "react";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { PdfPreview } from "@circle-react/components/shared/AttachmentPreview/PdfPreview";
import { Icon } from "@circle-react/components/shared/Icon";
import { contentTypeParser } from "@circle-react/components/shared/uikit/TipTap/utilities/contentTypeParser";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Loader } from "@circle-react-uikit/Loader";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Arrow } from "./Arrow";

export interface AttachmentGalleryProps {
  attachments?: any[];
  startIndex?: number;
}

export const AttachmentGalleryComponent = ({
  attachments = [],
  startIndex = 0,
}: AttachmentGalleryProps) => {
  const [index, setIndex] = useState(startIndex);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const drawer = useModal();

  useEffect(() => {
    () => drawer.remove();
  });

  const canGoNext = index < attachments.length - 1;
  const canGoPrevious = index > 0;

  const handleNextClick = () => canGoNext && setIndex(index + 1);
  const handlePreviousClick = () => canGoPrevious && setIndex(index - 1);

  const currentAttachment = attachments[index];
  const imageVariants = currentAttachment.image_variants || {};

  const isCurrentAttachmentPdf = contentTypeParser.isPDF(
    currentAttachment.content_type,
  );
  const isCurrentAttachmentImage = contentTypeParser.isImage(
    currentAttachment.content_type,
  );

  useHotkeys("left", handlePreviousClick, { enabled: canGoPrevious }, [
    canGoPrevious,
    index,
    attachments,
  ]);

  useHotkeys("right", handleNextClick, { enabled: canGoNext }, [
    canGoNext,
    index,
    attachments,
  ]);

  useEffect(() => {
    if (isCurrentAttachmentImage && isImageLoaded) {
      setIsImageLoaded(false);
    }
  }, [currentAttachment]);

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={() => drawer.remove()}
      title={currentAttachment.filename}
    >
      <Modal.Overlay />
      <Modal.Content size="full">
        <Modal.Header>
          <Typography.LabelMd weight="medium">
            {currentAttachment.filename}
          </Typography.LabelMd>
          <Modal.HeaderActions className="items-center">
            <a
              className="ml-2 focus:outline-none"
              href={currentAttachment.url}
              download
              target="_blank"
              rel="noreferrer"
            >
              <Icon className="text-dark fill-current" type="download" />
            </a>
            <Modal.CloseButton onClick={drawer.remove} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="flex items-center justify-between">
          <div className="w-16">
            {canGoPrevious && (
              <Arrow direction="left" onClick={handlePreviousClick} />
            )}
          </div>

          <div className="flex max-h-full flex-1 select-none justify-center">
            {isCurrentAttachmentPdf && (
              <PdfPreview src={currentAttachment.url} />
            )}
            {isCurrentAttachmentImage && (
              <>
                <img
                  loading="eager"
                  alt=""
                  className={classNames("object-contain", {
                    hidden: !isImageLoaded,
                  })}
                  src={imageVariants.large || currentAttachment.url}
                  onLoad={() => setIsImageLoaded(true)}
                  onError={() => setIsImageLoaded(true)}
                />
                {!isImageLoaded && <Loader center />}
              </>
            )}
          </div>
          <div className="w-16">
            {canGoNext && <Arrow direction="right" onClick={handleNextClick} />}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const AttachmentGallery = ModalManager.create(
  ({ startIndex, attachments }: AttachmentGalleryProps) => (
    <AttachmentGalleryComponent
      startIndex={startIndex}
      attachments={attachments}
    />
  ),
);

export const useAttachmentGallery = () => useModal(AttachmentGallery);
