import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { TICKET_TYPES } from "@circle-react/components/EventsV3/Modal/utils";
import { ViewType } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/Common";
import { useLiveStreamHoursAvailable } from "@circle-react/components/LiveStreams/hooks";
import { useRecordingHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useStreamHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { ROOM_ACCESS_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import { Form } from "@circle-react-uikit/Form";
import { FormItem } from "../../../../Common";
import { AccessType as AccessTypeEvents } from "../AccessType";

export const LiveStreamLocation = ({
  recordingEnabled,
  showRecordingToggle = false,
  showAddonsLimits = false,
  roomTypeOverride,
  event,
}) => {
  const {
    streamingHoursAvailable,
    recordingHoursAvailable,
    streamingTotalPlanHours,
    recordingTotalPlanHours,
  } = useLiveStreamHoursAvailable();
  const { limitUrlSharingEnabled } = useEventsFeatureFlags();
  const isEditMode = !!event;

  const { currentQuantity: recordingHoursAddonQuantity } =
    useRecordingHoursAddon();

  const { currentQuantity: streamHoursAddonQuantity } = useStreamHoursAddon();

  const showAddonsLimitsMessages =
    (showAddonsLimits && recordingLimitExpired) ||
    (showAddonsLimits && !streamingHoursAvailable);
  const recordingLimitExpired = recordingEnabled && !recordingHoursAvailable;

  const { watch, setValue } = useFormContext();
  const accessType = watch(
    "event_setting_attributes.live_stream_room_setting_attributes.access_type",
  );

  const ticketType = watch("event_setting_attributes.ticket_type");

  const shouldHideLimitUrlSharingToggle =
    accessType === ROOM_ACCESS_TYPES.PUBLIC_STREAM;

  const limitUrlSharingName =
    "event_setting_attributes.live_stream_room_setting_attributes.limit_url_sharing";

  const prevTicketTypeRef = useRef(
    event?.event_setting_attributes?.ticket_type,
  );

  const defaultLimitUrlSharingNameValue =
    isEditMode && prevTicketTypeRef.current == ticketType
      ? event?.event_setting_attributes?.live_stream_room_setting_attributes
          ?.limit_url_sharing
      : ticketType === TICKET_TYPES.PAID;

  useEffect(() => {
    if (accessType !== ROOM_ACCESS_TYPES.PUBLIC_STREAM) return;
    setValue(limitUrlSharingName, false, { shouldDirty: true });
  }, [accessType, setValue]);

  useEffect(() => {
    setValue(limitUrlSharingName, defaultLimitUrlSharingNameValue, {
      shouldDirty: true,
    });
  }, [ticketType, setValue]);

  return (
    <>
      <div className="flex items-stretch">
        <div className="w-1/2 pr-2">
          <FormItem
            name="event_setting_attributes.live_stream_room_setting_attributes.view_type"
            labelClassName="mb-3 text-lg"
            translationRoot="events.create.form"
          >
            <ViewType roomType={roomTypeOverride} />
          </FormItem>
        </div>
        <div className="w-1/2 pl-2">
          <FormItem
            name="event_setting_attributes.live_stream_room_setting_attributes.access_type"
            labelClassName="mb-3 text-lg"
            translationRoot="events.create.form"
          >
            <AccessTypeEvents roomType={roomTypeOverride} />
          </FormItem>
        </div>
      </div>
      {showRecordingToggle && (
        <div className="mt-4">
          <FormItem
            name="event_setting_attributes.live_stream_room_setting_attributes.recording_enabled"
            tooltipText={t(
              "events.create_modal.event_setting_attributes.recording_enabled_tooltip",
            )}
            inlineReverse
            translationRoot="events.create.form"
            className="!pt-1"
            labelClassName="!font-normal"
          >
            <Form.ToggleSwitch variant="small" />
          </FormItem>
          {recordingEnabled && (
            <FormItem
              name="event_setting_attributes.live_stream_room_setting_attributes.auto_post_recording_enabled"
              tooltipText={t(
                "events.create_modal.event_setting_attributes.auto_post_recording_enabled_tooltip",
              )}
              inlineReverse
              translationRoot="events.create.form"
              className="!pt-1"
              labelClassName="!font-normal"
            >
              <Form.ToggleSwitch variant="small" />
            </FormItem>
          )}
          <FormItem
            name="event_setting_attributes.live_stream_room_setting_attributes.mute_on_join"
            inlineReverse
            translationRoot="events.create.form"
            className="!pt-1"
            labelClassName="!font-normal"
          >
            <Form.ToggleSwitch variant="small" />
          </FormItem>
          <FormItem
            name="event_setting_attributes.live_stream_room_setting_attributes.hide_participants_list"
            tooltipText={t(
              `events.create.form.event_setting_attributes.live_stream_room_setting_attributes.hide_participants_list_tooltip`,
            )}
            inlineReverse
            translationRoot="events.create.form"
            className="!pt-1"
            labelClassName="!font-normal"
          >
            <Form.ToggleSwitch variant="small" />
          </FormItem>
        </div>
      )}
      <FormItem
        name="event_setting_attributes.hide_location_from_non_attendees"
        inlineReverse
        translationRoot="events.create.form"
        className="!pt-1"
        labelClassName="!font-normal"
      >
        <Form.ToggleSwitch variant="small" />
      </FormItem>
      {limitUrlSharingEnabled && !shouldHideLimitUrlSharingToggle && (
        <FormItem
          name={limitUrlSharingName}
          translationRoot="events.create.form"
          tooltipText={t(
            `events.create_modal.event_setting_attributes.limit_url_sharing_tooltip`,
          )}
          inlineReverse
          className="!pt-1"
          labelClassName="!font-normal"
        >
          <Form.ToggleSwitch variant="small" />
        </FormItem>
      )}
      {showAddonsLimitsMessages ? (
        <div className="mt-12">
          {recordingLimitExpired && (
            <div className="px-1 text-justify">
              <Typography.BodyXs>
                {t("live_streams.form.upgrade_recording_hours_label", {
                  hours: recordingTotalPlanHours + recordingHoursAddonQuantity,
                })}
              </Typography.BodyXs>
            </div>
          )}
          {!streamingHoursAvailable && (
            <div className="px-1 text-justify">
              <Typography.BodyXs>
                {t("live_streams.form.upgrade_streaming_hours_label", {
                  hours: streamingTotalPlanHours + streamHoursAddonQuantity,
                })}
              </Typography.BodyXs>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

LiveStreamLocation.propTypes = {
  recordingEnabled: PropTypes.bool,
  showRecordingToggle: PropTypes.bool,
  showAddonsLimits: PropTypes.bool,
  roomTypeOverride: PropTypes.string,
};
