import { Form } from "@circle-react-uikit/Form";

interface FormItemProps {
  children: React.ReactNode;
  name: string;
  rules?: any;
  tooltipText?: string;
  hidePlaceholder?: boolean;
  placeholder?: string;
  inlineReverse?: boolean;
  translationRoot?: string;
  hideLabel?: boolean;
  className?: string;
}

export const FormItem = ({
  children,
  name,
  rules = {},
  tooltipText,
  hidePlaceholder = false,
  placeholder,
  inlineReverse = false,
  translationRoot = "events.edit_modal",
  hideLabel = false,
  className = "",
  ...props
}: FormItemProps) => (
  <Form.Item
    name={name}
    hideDescription
    translationRoot={translationRoot}
    rules={rules}
    hideBorder
    fullWidth
    hidePlaceholder={hidePlaceholder}
    placeholder={placeholder}
    tooltipText={tooltipText}
    inlineReverse={inlineReverse}
    hideLabel={hideLabel}
    className={className}
    {...props}
  >
    {children}
  </Form.Item>
);
